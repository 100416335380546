const questions = [
  {
    questionText: "Why are you seeking the truth?",
    answerOptions: [
      {
        answerText:
          "Everyone knows the truth, so why can’t I? I want to know it, too.",
        category: "cat1",
      },
      {
        answerText: "I want to impress my cat with my knowledge.",
        category: "cat2",
      },
      {
        answerText: "42.",
        category: "cat3",
      },
      {
        answerText: "My boss says it’s important.",
        category: "cat4",
      },
    ],
    image: "images/01.webp",
  },

  {
    questionText: "Where lies your strength?",
    answerOptions: [
      {
        answerText:
          "In my abs, man. Been hitting the gym three times a week lately.",
        category: "cat1",
      },
      {
        answerText: "I live in the same apartment block as Mr. Shusharin.",
        category: "cat3",
      },
      {
        answerText: "I get my tasks done like a real pro.",
        category: "cat2",
      },
      {
        answerText: "But what is strength? What is it measured in?",
        category: "cat4",
      },
    ],
    image: "images/02.webp",
  },

  {
    questionText: "What do you bring to this realm?",
    answerOptions: [
      {
        answerText: "Not much, really.",
        category: "cat3",
      },
      {
        answerText: "Happiness and beauty.",
        category: "cat2",
      },
      {
        answerText: "Law and order.",
        category: "cat1",
      },
      {
        answerText: "The socks and sandals aesthetics.",
        category: "cat4",
      },
    ],
    image: "images/03.webp",
  },

  {
    questionText: "What came first: the chicken or the egg?",
    answerOptions: [
      {
        answerText: "No idea. Haven’t seen what’s for lunch today, yet.",
        category: "cat3",
      },
      {
        answerText:
          "The rooster who wanted some attention from the lady chicken.",
        category: "cat2",
      },
      {
        answerText: "Whoever was the first in line.",
        category: "cat1",
      },
      {
        answerText: "Dinosaurs.",
        category: "cat4",
      },
    ],
    image: "images/04.webp",
  },

  {
    questionText: "What should people do with the emptiness inside?",
    answerOptions: [
      {
        answerText: "Throw some chocolate in there.",
        category: "cat3",
      },

      {
        answerText: "Fill it with butterflies.",
        category: "cat2",
      },
      {
        answerText: "Make it get a job and pay the rent.",
        category: "cat1",
      },
      {
        answerText: "But what is emptiness? And inside of what?",
        category: "cat4",
      },
    ],
    image: "images/05.webp",
  },

  {
    questionText: "How can one find success?",
    answerOptions: [
      {
        answerText: "See every mistake as a learning opportunity.",
        category: "cat4",
      },
      {
        answerText: "Nah-ah, not telling you. It’s a commercial secret.",
        category: "cat2",
      },
      {
        answerText: "Set realistic goals. “Get something to eat,” for example.",
        category: "cat3",
      },
      {
        answerText: "Close all of their HelpDesk tickets.",
        category: "cat1",
      },
    ],
    image: "images/06.webp",
  },

  {
    questionText: "People are almighty so long as...",
    answerOptions: [
      {
        answerText: "…there are no fines for being late.",
        category: "cat3",
      },
      {
        answerText: "…the coffee machine is working.",
        category: "cat1",
      },
      {
        answerText:
          "…they’re certain that their microphone’s off during a Zoom call.",
        category: "cat2",
      },
      {
        answerText:
          "…they manage to avoid an hour-long discussion of a five-minute issue.",
        category: "cat4",
      },
    ],
    image: "images/07.webp",
  },

  {
    questionText: "What makes you happy?",
    answerOptions: [
      {
        answerText: "The most magic word of all — “Approved.”",
        category: "cat1",
      },
      {
        answerText: "Champagne bubbles at the New Year Namonas party.",
        category: "cat2",
      },
      {
        answerText: "Friday, Friday, Friday!",
        category: "cat3",
      },
      {
        answerText: "Sorry, can’t tell you that. I signed an NDA..",
        category: "cat4",
      },
    ],
    image: "images/08.webp",
  },
];

export default questions;
