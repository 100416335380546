import Title from "./components/Title/Title";
import Paragraph from "./components/Paragraph/Paragraph";
import Button from "./components/Button/Button";
import OptionsItem from "./components/OptionsItem/OptionsItem";
import React, { useEffect, useState } from "react";
import imagePrev from "./assets/images/00.webp";
import imageResult1 from "./assets/images/01.webp";
import imageResult2 from "./assets/images/02.webp";
import imageResult3 from "./assets/images/03.webp";
import imageResult4 from "./assets/images/04.webp";
import imageLink1 from "./assets/images/01.jpg";
import imageLink2 from "./assets/images/02.jpg";
import imageLink3 from "./assets/images/03.jpg";
import imageLink4 from "./assets/images/04.jpg";
import styles from "./App.module.scss";
import questions from "./state/questions";
import classNames from "classnames";
import Result from "./components/Result/Result";

function App() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [stateFlow, setStateFlow] = useState("Prev");
  const [option, setOption] = useState(false);
  const [resultState, setResultState] = useState("");

  const [counterCat1, setCounterCat1] = useState(1);
  const [counterCat2, setCounterCat2] = useState(1);
  const [counterCat3, setCounterCat3] = useState(1);
  const [counterCat4, setCounterCat4] = useState(1);

  const optionActiveHandler = (option) => {
    setOption(option);

    setTimeout(() => {
      window.scrollTo({ top: 1000, behavior: "smooth" });
    }, "50");
  };

  const catOne = (cat1) => {
    setCounterCat1(counterCat1 + cat1);
  };

  const catTwo = (cat2) => {
    setCounterCat2(counterCat2 + cat2);
  };

  const catThree = (cat3) => {
    setCounterCat3(counterCat3 + cat3);
  };

  const catFour = (cat4) => {
    setCounterCat4(counterCat4 + cat4);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handlerNext = () => {
    if (currentQuestion === 7) {
      setStateFlow("End");

      setResultState(() => {
        if (
          counterCat1 >= counterCat2 &&
          counterCat1 >= counterCat3 &&
          counterCat1 >= counterCat4
        ) {
          setResultState(() => {
            return (
              <Result
                title="You’re the Achiever Dragon!"
                subTitle="And that’s some solid potential for the Golden Namonas award right there!"
                text="Keep it up! The New Year’s going to make your career reach new heights where dragons fly. Let your professional intuition lead you to right decisions, and may all of your efforts be rewarded.
                Happy New Year!"
                imageSrc={imageResult1}
                imageLink={imageLink1}
              />
            );
          });

          scrollToTop();
        }

        if (
          counterCat2 >= counterCat1 &&
          counterCat2 >= counterCat3 &&
          counterCat2 >= counterCat4
        ) {
          setResultState(() => {
            return (
              <Result
                title="You’re the Magnificent Dragon!"
                subTitle="There’s beauty in everything. "
                text="In 2024, we wish you to have a ton of reasons to be happy and start every morning with a smile and anticipation of something incredible. Fill ordinary things with magic, and all your wishes will come true.
                Happy New Year!"
                imageSrc={imageResult2}
                imageLink={imageLink2}
              />
            );
          });

          scrollToTop();
        }

        if (
          counterCat3 >= counterCat1 &&
          counterCat3 >= counterCat2 &&
          counterCat3 >= counterCat4
        ) {
          setResultState(() => {
            return (
              <Result
                title="You’re the Chill Dragon!"
                subTitle="Everything’s as easy as a dragon flies."
                text="We wish you to strike a perfect balance between work and life. The best things happen when they’re not planned, so may the upcoming year be full of nice surprises.
                Happy New Year!"
                imageSrc={imageResult3}
                imageLink={imageLink3}
              />
            );
          });

          scrollToTop();
        }

        if (
          counterCat4 >= counterCat1 &&
          counterCat4 >= counterCat2 &&
          counterCat4 >= counterCat3
        ) {
          setResultState(() => {
            return (
              <Result
                title="You’re the Cool-Headed Dragon!"
                subTitle="Everything needs balance"
                text="Let the upcoming year be as calm and balanced as you are. May work bring you satisfaction and not stress you out.
                Happy New Year!"
                imageSrc={imageResult4}
                imageLink={imageLink4}
              />
            );
          });

          scrollToTop();
        }
      });
    } else {
      setOption(false);
      setCurrentQuestion(currentQuestion + 1);

      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, "50");
    }
  };

  const handlerBack = () => {
    setOption(false);
    setStateFlow("Prev");
    setCurrentQuestion(0);
  };

  const [offset, setOffset] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      if (window.scrollY > 50) {
        setOffset(true);
      } else {
        setOffset(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={styles.wrapperContainer}>
        {stateFlow === "Prev" ? (
          <>
            <div className={styles.posterContainer}>
              <img src={imagePrev} className={styles.image} alt="" />
            </div>

            <div className={styles.firstscreenContainer}>
              <div>
                <Title value="Learn the truth about yourself" />
                <Paragraph value="A mighty reward awaits those who are brave and resilient and are not afraid to learn the truth." />

                <Paragraph
                  value="Take this quiz and find out what kind of dragon you are!"
                  strong
                />

                <Button
                  className="button-one"
                  value="Go"
                  onClick={() => setStateFlow("Flow")}
                />
              </div>
            </div>
          </>
        ) : stateFlow === "Flow" ? (
          <>
            <div className={styles.posterContainer}>
              <img
                src={questions[currentQuestion].image}
                className={styles.image}
                alt={questions[currentQuestion].image}
              />
            </div>

            <div
              className={classNames({
                [styles.optionsContainer]: true,
                [styles.onScrollOption]: offset,
              })}
            >
              <div>
                <div className={styles.header}>
                  <div className={styles.topBarContainer}>
                    <div>
                      Question {currentQuestion + 1} of {questions.length}
                    </div>
                    <div onClick={handlerBack} className={styles.back}>
                      Home
                    </div>
                  </div>
                  <div className={styles.titleWrapper}>
                    <Title value={questions[currentQuestion].questionText} />
                  </div>
                </div>

                <div className={styles.optionsWrapper}>
                  {questions[currentQuestion].answerOptions.map((item) => (
                    <OptionsItem
                      key={item.answerText}
                      title={item.answerText}
                      resultText={item.answerResult}
                      onClick={optionActiveHandler}
                      disabled={option}
                      category={item.category}
                      cat1={catOne}
                      cat2={catTwo}
                      cat3={catThree}
                      cat4={catFour}
                    />
                  ))}
                </div>

                <div className={styles.buttonContainer}>
                  <Button
                    className={styles.buttonNotfix}
                    value="Next"
                    onClick={() => handlerNext()}
                    disabled={!option}
                    id={"btn" + (currentQuestion + 1)}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>{resultState}</>
        )}
      </div>
    </>
  );
}

export default App;
