/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Title from "../Title/Title";
import Button from "../Button/Button";
import Paragraph from "../Paragraph/Paragraph";
import styles from "./Result.module.scss";
import classNames from "classnames";

function Result({ title, text, subTitle, imageSrc, imageLink }) {
  const [showConditions] = useState(false);

  const [offset, setOffset] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      if (window.scrollY > 50) {
        setOffset(true);
      } else {
        setOffset(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {!showConditions ? (
        <>
          <div className={styles.posterContainer}>
            <img src={imageSrc} className={styles.image} alt="" />
          </div>
          <div className={styles.resultContainer}>
            <div
              className={classNames({
                [styles.resultContainerBox]: true,
                [styles.onScrollOption]: offset,
              })}
            >
              <Title value={title} />
              <Paragraph className={styles.subTitle} value={subTitle} />
              <Paragraph value={text} />
              <div className={styles.promoBox}>
                <Paragraph
                  className={styles.paragraph}
                  value="Pick up a gift - desktop wallpaper for your computer"
                />

                <div className={styles.promoButtonsWrapper}>
                  <div className={styles.buttonBox}>
                    <a href={imageLink} download="wallpaper" rel="noreferrer">
                      <Button
                        value="Download Wallpaper"
                        className={styles.buttonPresent}
                      />
                    </a>
                    <a href="/" rel="noreferrer">
                      <Button
                        value="Go through the quiz again?"
                        className={styles.buttonOutline}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.posterContainer}>
            <img src={imageSrc} className={styles.image} alt="" />
          </div>
        </>
      )}
    </>
  );
}

export default Result;
